export const Alert = ({ id, title, children, onClose, onRetry, onCancel, ...props }) => (
  <div id={id} className="alert" {...props}>
    <div className="alert-inside">
      <AlertNav title={title} onClose={onClose} />
      <AlertContent>{children}</AlertContent>
      {/* <AlertFooter onRetry={onRetry} onCancel={onClose} /> */}
    </div>
  </div>
);

const AlertButton = ({
  children,
  onClick,
  underline,
  tabIndex = 0,
  dataRetry,
}) => (
  <button
    className={`button ${underline ? "underline" : ""}`}
    onClick={onClick}
    tabIndex={tabIndex}
    data-retry={dataRetry}
  >
    <span>{children}</span>
  </button>
);

const AlertNav = ({ title, onClose }) => (
  <div className="alert-nav">
    <h3>
      <span>{title}</span>
    </h3>
    <div className="buttons">
      <label className="close" onClick={onClose}></label>
    </div>
  </div>
);

const AlertContent = ({ children }) => (
  <div className="alert-content">{children}</div>
);

const AlertFooter = ({ onRetry, onCancel }) => (
  <footer className="alert-footer">
    <AlertButton onClick={onRetry} underline tabIndex={0} dataRetry={true}>
      Bookuję was
    </AlertButton>
    <AlertButton onClick={onCancel} tabIndex={0}>
      Zamykaj to
    </AlertButton>
  </footer>
);

