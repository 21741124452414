import WindowWelcome from "./components/desktop/window/WindowWelcome";
import { Window } from "./components/desktop/window/Window";
import { Alert } from "./components/desktop/alert";
import BlueScreenError from "./components/desktop/BlueScreen";
import Icon from "./components/desktop/icon/Icon";
import StartMenu from "./components/desktop/StartMenu";
import { useState } from "react";
import useSound from "use-sound";
import clickSound from "./assets/sounds/switch-on.mp3";
import DrumKit from "./components/games/DrumKit";
import { GamesWindow } from "./GamesWindow";

const BOOKING = "booking";
const ABOUT = "about";

export function App() {
  const [windows, setWindows] = useState([
    {
      id: "welcome",
      title: "Witamy",
      component: WindowWelcome,
      isOpen: true,
      zIndex: 1,
    },
    {
      id: "video",
      title: "Ostatni raz",
      component: Window,
      isOpen: false,
      zIndex: 0,
    },
    {
      id: "player",
      title: "Spotify player",
      component: Window,
      isOpen: false,
      zIndex: 0,
    },
    {
      id: "games",
      title: "Gierki",
      component: GamesWindow,
      isOpen: false,
      zIndex: 0,
    },
    {
      id: BOOKING,
      title: "Booking",
      component: Alert,
      isOpen: false,
      zIndex: 0,
    },
    {
      id: ABOUT,
      title: "O nas",
      component: Window,
      isOpen: false,
      zIndex: 0,
    },
  ]);

  const [showBlueScreen, setShowBlueScreen] = useState(false);

  const [playClick] = useSound(clickSound);

  const openWindow = (id) => {
    playClick();
    setWindows((prevWindows) => {
      const maxZIndex = Math.max(...prevWindows.map((w) => w.zIndex));
      return prevWindows.map((window) =>
        window.id === id
          ? { ...window, isOpen: true, zIndex: maxZIndex + 1 }
          : window
      );
    });
  };

  const closeWindow = (id) => {
    playClick();
    setWindows((prevWindows) =>
      prevWindows.map((window) =>
        window.id === id ? { ...window, isOpen: false } : window
      )
    );
  };

  const bringToFront = (id) => {
    setWindows((prevWindows) => {
      const maxZIndex = Math.max(...prevWindows.map((w) => w.zIndex));
      return prevWindows.map((window) =>
        window.id === id ? { ...window, zIndex: maxZIndex + 1 } : window
      );
    });
  };

  return (
    <>
      <input hidden data-start id="start" type="checkbox" />

      <main>
        <div id="desktop">
          <div id="icons">
            <Icon
              label="Teledysk"
              iconClass="label-multimedia"
              onClick={() => openWindow("video")}
            />
            <Icon
              label="Pitu Pitu"
              iconClass="label-wordpad"
              style={{
                opacity: 0.25,
                userSelect: "none",
              }}
            />
            <Icon
              label="Muza"
              iconClass="label-cd"
              onClick={() => openWindow("player")}
            />
            <Icon
              label="O nas"
              iconClass="label-skip-awgs-x2"
              onClick={() => openWindow(ABOUT)}
            />
            <Icon
              label="Gierki"
              iconClass="label-joystick"
              // onClick={() => openWindow("games")}
              style={{
                opacity: 0.25,
                userSelect: "none",
              }}
            />
          </div>

          <div id="windows">
            {windows.map(
              ({ id, title, component: WindowComponent, isOpen, zIndex }) => (
                <WindowComponent
                  key={id}
                  title={title}
                  show={isOpen}
                  style={{
                    zIndex,
                    visibility: isOpen ? "visible" : "hidden",
                  }}
                  onClick={() => bringToFront(id)}
                  onClose={() => closeWindow(id)}
                >
                  {id === "welcome" && (
                    <>
                      Hello ty, tu powstaje strona zespołu Miętha... Wpadnij za
                      kilka dni...
                      <br />
                      <br />
                      Tymaczasem poczuj się AWGSem w akcji.
                      <br />
                      Łapska na klawiaturkę i jedziemy.
                      <br />
                      <br />
                      <DrumKit />
                    </>
                  )}

                  {id === "about" && (
                    <>
                      <p>
                        Miętha – intrygujący duet, który tworzą wokalista i
                        autor tekstów Skip oraz producent muzyczny AWGS.
                      </p>
                      <p>
                        Kreowane przez nich brzmienie balansuje na styku różnych
                        gatunków, dlatego umieszczanie ich twórczości w
                        szufladkach byłoby dla zespołu krzywdzące.
                      </p>
                      <p>
                        Na swoim koncie mają trzy albumy długogrające:
                        Audioportret – napisany w ciągu pierwszego pół roku
                        znajomości – Szum i 36,6.
                      </p>

                      <p>
                        Pojawiali się we wspólnych utworach z
                        najpopularniejszymi raperami rodzimej sceny (Paluch,
                        Otsochodzi, Miły Atz), jak i z czołowymi artystami sceny
                        popowej (Sanah, Vito Bambino, Natalia Szroeder).
                      </p>

                      <p>
                        Do 2022 roku Skip i AWGS związani byli z wytwórnią
                        Asfalt Records, obecnie współpracują z Sony Music
                        Entertainment Poland gdzie pod nowa banderą ukaże się
                        najnowszy album o tytule “Mięthlik”.
                      </p>
                    </>
                  )}

                  {id === "video" && (
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/YzdlmezVH9Q?si=miVaENCDLuu2QrZp"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  )}

                  {id === "player" && (
                    <iframe
                      style={{ backgroundColor: "#282828" }}
                      src="https://open.spotify.com/embed/playlist/37i9dQZF1EIWr3sUkLKQso?utm_source=generator&theme=0"
                      width="100%"
                      height="434"
                      frameBorder="0"
                      allowFullScreen
                      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                      loading="lazy"
                    ></iframe>
                  )}

                  {id === BOOKING && (
                    <>
                      <p>
                        Booking:{" "}
                        <a href="mailto:klaudia@soldoutagencja.pl">
                          klaudia@soldoutagencja.pl
                        </a>
                      </p>
                      <p>+48 698 799 895</p>
                    </>
                  )}
                </WindowComponent>
              )
            )}
          </div>
        </div>

        {showBlueScreen && (
          <div id="alerts">
            <BlueScreenError onClick={() => setShowBlueScreen(false)} />
          </div>
        )}

        <StartMenu
          onShutDown={() => setShowBlueScreen(true)}
          onBooking={() => openWindow(BOOKING)}
        />
      </main>
    </>
  );
}
