const BlueScreenError = ({ onClick }) => {
    return (
      <div id="alerts" onClick={onClick}>
        <div id="alert-blue-screen">
          <div className="content">
            <h3>
              <span>Windows</span>
            </h3>
            <p>An error has occurred. To continue:</p>
            <p>Press Enter to return to Windows, or</p>
            <p>Press CTRL to restart your computer. If you do this, you will lose any unsaved information in all open applications.</p>
            <p>Error: Miętha</p>
            <p>
              Press any key to continue
              <span className="blink">_</span>
            </p>
          </div>
        </div>
      </div>
    );
  };

  export default BlueScreenError;